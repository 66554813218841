export class WrongPhasesError extends Error {
  public constructor() {
    super('Wrong phases');
  }
}

export class SiteNotFoundError extends Error {
  public constructor() {
    super('Site not found');
  }
}

export class UnableToUpdateSiteAreaError extends Error {
  public constructor() {
    super('Unable to update site area');
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StatusCodes } from 'http-status-codes';
import {
  SiteNotFoundError,
  UnableToUpdateSiteAreaError,
  WrongPhasesError,
} from '@repository/site-area/errors/update-site-area.error';
import { SiteAreaApiService } from '../../api/site-area/site-area.api.service';
import { SiteArea, SiteAreaSmartChargingSettings, SubSiteAreaAction } from '../../types/SiteArea';
import { ActionResponse, SiteAreaDataResult } from '../../types/DataResult';
import { RestResponse } from '../../types/GlobalType';
import { HTTPError } from '../../types/HTTPError';

@Injectable()
export class SiteAreaRepositoryService {
  public constructor(private readonly siteAreaApi: SiteAreaApiService) {}

  public getSiteArea(
    siteAreaId: string,
    options: { withSite?: boolean; withParentSiteArea?: boolean } = {},
  ): Observable<SiteArea> {
    return this.siteAreaApi.getSiteArea(siteAreaId, options).pipe(
      map((siteArea) => {
        // Transform Amps to Watt
        if (siteArea.smartChargingParameters?.defaultCurrent) {
          siteArea.smartChargingParameters!.defaultCurrent = this.ampsToWatt(
            siteArea.smartChargingParameters?.defaultCurrent,
            siteArea.voltage,
            siteArea.numberOfPhases,
          );
        }

        // Transform Amps to Watt
        if (siteArea.smartChargingParameters?.minimalCurrent) {
          siteArea.smartChargingParameters.minimalCurrent = this.ampsToWatt(
            siteArea.smartChargingParameters?.minimalCurrent,
            siteArea.voltage,
            siteArea.numberOfPhases,
          );
        }

        if (siteArea.smartChargingParameters?.safetyMargin) {
          siteArea.smartChargingParameters.safetyMargin = siteArea.smartChargingParameters.safetyMargin * 100;
        }

        return siteArea;
      }),
    );
  }

  public getSiteAreas(
    limit = 50,
    skip = 0,
    params: { Issuer?: boolean; ParentSiteAreaId?: string; Search?: string } = {},
  ): Observable<SiteAreaDataResult> {
    return this.siteAreaApi.getSiteAreas(limit, skip, params);
  }

  public updateSiteArea(siteArea: SiteArea, subSiteAreaActions: SubSiteAreaAction[] = []): Observable<ActionResponse> {
    const updatedSiteArea = this.updateSiteAreaSmartChargingInformation(siteArea);

    return this.siteAreaApi.updateSiteArea(updatedSiteArea, subSiteAreaActions).pipe(
      map((response) => {
        if (response.status !== RestResponse.SUCCESS) {
          throw new Error('Site Area could not be updated');
        }

        return response;
      }),
      catchError((error) => {
        switch (error.status) {
          case HTTPError.THREE_PHASE_CHARGER_ON_SINGLE_PHASE_SITE_AREA:
            throw new WrongPhasesError();
          case StatusCodes.NOT_FOUND:
            throw new SiteNotFoundError();
          default:
            throw new UnableToUpdateSiteAreaError();
        }
      }),
    );
  }

  public createSiteArea(siteArea: SiteArea, subSiteAreaActions: SubSiteAreaAction[] = []): Observable<ActionResponse> {
    const updatedSiteArea = this.updateSiteAreaSmartChargingInformation(siteArea);

    return this.siteAreaApi.createSiteArea(updatedSiteArea, subSiteAreaActions).pipe(
      map((response) => {
        if (response.status !== RestResponse.SUCCESS) {
          throw new Error('Site Area could not be created');
        }

        return response;
      }),
    );
  }

  public updateSmartChargingSettings(siteArea: SiteAreaSmartChargingSettings): Observable<void> {
    return this.siteAreaApi.updateSmartChargingSettings(siteArea);
  }

  private updateSiteAreaSmartChargingInformation(siteArea: SiteArea) {
    const updatedSiteArea = { ...siteArea };

    // Convert watt to amps
    if (updatedSiteArea.smartChargingParameters?.defaultCurrent) {
      updatedSiteArea.smartChargingParameters.defaultCurrent = this.wattToAmps(
        updatedSiteArea.smartChargingParameters?.defaultCurrent,
        updatedSiteArea.voltage,
        updatedSiteArea.numberOfPhases,
      );
    }

    // Convert watt to amps
    if (updatedSiteArea.smartChargingParameters?.minimalCurrent) {
      updatedSiteArea.smartChargingParameters.minimalCurrent = this.wattToAmps(
        updatedSiteArea.smartChargingParameters?.minimalCurrent,
        updatedSiteArea.voltage,
        updatedSiteArea.numberOfPhases,
      );
    }

    if (updatedSiteArea.smartChargingParameters?.safetyMargin) {
      updatedSiteArea.smartChargingParameters.safetyMargin = updatedSiteArea.smartChargingParameters.safetyMargin / 100;
    }

    return updatedSiteArea;
  }

  private ampsToWatt(amps: number, voltage: number, numberOfPhases: number): number {
    if (amps === 0 || voltage === 0 || numberOfPhases === 0) {
      return 0;
    }

    return amps * voltage * numberOfPhases;
  }

  private wattToAmps(watt: number, voltage: number, numberOfPhases: number): number {
    if (watt === 0 || voltage === 0 || numberOfPhases === 0) {
      return 0;
    }

    return watt / voltage / numberOfPhases;
  }
}
